window.LISTEN = {
  ctrlShiftLeft:{
     info: () => {console.log('listen ctrlShiftLeft')},
     excute: () => {console.log('ctrlShiftLeft trigger')}
  },
  ctrlAltLeft:{
     info: () => {console.log('listen ctrlAltLeft')},
     excute: () => {console.log('ctrlAltLeft trigger')}
  },  
}

const listenKeyboard = () => {   
   document.body.addEventListener('keyup',async  function(e){
        //console.log(e.ctrlKey,e.code)
     
        if(e.ctrlKey && e.code == 'ShiftLeft'){
           try{
             window.LISTEN.ctrlShiftLeft.excute()
           } catch (e){console.log(e)}
        }
     
        if(e.ctrlKey && e.code == 'AltLeft'){
           console.log('-----------',e.ctrlKey,e.code)
           try{
             window.LISTEN.ctrlAltLeft.excute()
           } catch (e){console.log(e)}
        }     
   })
   console.log('Keyboard Listening') 
}

listenKeyboard()






/**
https://keyjs.dev
alt  18
tab  9
shift   16
enter   13
ctrl   17
home  36
page down  34
end    35
escape  27
page up    33
caps lock   20
pause/break  19
backspace   8
delete  46
right arrow   39
down arrow  40
left arrow  37
up arrow  38
insert  45
0   48
1   49
2   50
3   51
4   52
5   53
6   54
7   55
8   56
9   57
a   65
b   66
c   67
d   68
e   69
f   70
g   71
h   72
i   73
j   74
k   75
l   76
m   77
n   78
o   79
p   80
q   81
r   82
s   83
t   84
u   85
v   86
w   87
x   88
y   89
z   90
select key  93
left window key    91
right window key   92
numpad 0   96
numpad 1   97
numpad 2   98
numpad 3   99
numpad 4   100
numpad 5   101
numpad 6   102
numpad 7   103
numpad 8  104
numpad 9  105
multiply    106
divide  111
decimal point  110
add     107
subtract  109
f1  112
f2  113
f3  114
f4  115
f5  116
f6  117
f7  118
f8  119
f9  120
f10  121
f11  122
f12  123
single quote   222
close bracket   221
open bracket   219
back slash  220
equal sign  187
comma   188
dash    189
period  190
forward slash  191
grave accent  192
num lock  144
scroll lock   145
semi-colon  186
**/
